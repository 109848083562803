@import "antd/dist/reset.css";

body {
 margin: 0;
 font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 line-height: 1.5715;
}

code {
 font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input {
 background-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
 -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

*,
*::after,
*::before {
 box-sizing: border-box;
 font-family: Poppins;
}

/* *::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #7a78a9;
  border-radius: 5px;
} */

@keyframes slideDownAlert {
 from {
  transform: translateY(-100%);
  opacity: 0;
 }
 to {
  transform: translateX(0);
  opacity: 1;
 }
}

@keyframes animationPicker {
 from {
  transform: translateY(-10%);
  opacity: 0;
 }
 to {
  transform: translateX(0);
  opacity: 1;
 }
}

/* hide arrows input number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
 -webkit-appearance: none;
 margin: 0;
}

/* Firefox */
input[type="number"] {
 -moz-appearance: textfield;
}
