@font-face {
 font-family: "Poppins-light";
 src: local("Poppins Light"), local("Poppins-Light"),
  url("../fonts/poppins/Poppins-Light.woff2") format("woff2");
 font-weight: 300;
 font-style: normal;
 font-display: swap;
}
@font-face {
 font-family: "Poppins";
 src: local("Poppins"), local("IBMPlexSans"),
  url("../fonts/poppins/Poppins-Regular.woff2") format("woff2");
 font-weight: 400;
 font-style: normal;
 font-display: swap;
}
@font-face {
 font-family: "Poppins-bold";
 src: local("Poppins Medium"), local("Poppins-Medium"),
  url("../fonts/poppins/Poppins-Medium.woff2") format("woff2");
 font-weight: 500;
 font-style: normal;
 font-display: swap;
}
